.filter-container {
    display: flex;
    flex-direction: row;
    justify-content: right;
    align-items: center;
}

.action-filter {
    color: #13772e;
}

.created-by-filter {
    margin-left: 10px;
}

.date-filter {
    
}

.date-button   {
    margin-right: 5px;
}
    
.clear-filter {
    margin-right:  10px;
}
.confirm-dates {
    margin-top: 5px;
}